var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isReadOnly
        ? _c(
            "b-row",
            { staticClass: "pb-1" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c("e-button", {
                    attrs: { variant: "primary", text: _vm.$t("Adicionar") },
                    on: { click: _vm.onShowSidebar },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-table", {
                ref: "contact-table",
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  fields: _vm.fields,
                  items: _vm.contacts,
                  "empty-text": _vm.emptyTableMessage,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(telephone)",
                    fn: function (row) {
                      return [
                        _vm._v(" " + _vm._s(row.item.telephone.number) + " "),
                      ]
                    },
                  },
                  {
                    key: "cell(active)",
                    fn: function (row) {
                      return [
                        _c("e-status-badge", {
                          attrs: { status: row.item.active },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-grid-actions", {
                          attrs: { "show-update": true, "show-delete": true },
                          on: {
                            update: function ($event) {
                              return _vm.onShowSidebar(row)
                            },
                            delete: function ($event) {
                              return _vm.deleteContact(row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isReadOnly
        ? _c("e-sidebar", {
            attrs: {
              show: _vm.showSidebar,
              fetching: _vm.loading,
              title: _vm.isEdit
                ? _vm.$t("Alterar Contato")
                : _vm.$t("Novo Contato"),
              width: "600px",
            },
            on: {
              save: _vm.addContactClick,
              hidden: function ($event) {
                _vm.showSidebar = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "FormulateForm",
                        { ref: "formContact", attrs: { name: "formContact" } },
                        [
                          _c(
                            "b-card-actions",
                            {
                              attrs: {
                                title: _vm.$t("Contato"),
                                "no-actions": "",
                              },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "contact-name",
                                          type: "text",
                                          label: _vm.$t("Nome"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.contactForm.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "contact-role-description",
                                          type: "text",
                                          label: _vm.$t("Cargo"),
                                          validation: "required",
                                        },
                                        model: {
                                          value:
                                            _vm.contactForm.roleDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "roleDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contactForm.roleDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "contact-phone-type",
                                          type: "vue-select",
                                          placeholder: _vm.$t("Nenhum"),
                                          label: _vm.$t("Tipo do Telefone"),
                                          options: _vm.telephoneTypes(),
                                        },
                                        model: {
                                          value: _vm.contactForm.telephone.type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm.telephone,
                                              "type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contactForm.telephone.type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "contact-phone",
                                          type: "text-mask",
                                          mask: [
                                            "(##) #####-####",
                                            "(##) ####-####",
                                          ],
                                          validation: "optional|min:14",
                                          placeholder:
                                            _vm.$t("(99) 99999-9999"),
                                          label: _vm.$t("Número"),
                                        },
                                        model: {
                                          value:
                                            _vm.contactForm.telephone.number,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm.telephone,
                                              "number",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contactForm.telephone.number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "contact-email",
                                          type: "text",
                                          label: _vm.$t("E-mail"),
                                          placeholder: _vm.$t("nome@email.com"),
                                          validation: "optional|email",
                                        },
                                        model: {
                                          value: _vm.contactForm.email,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "email",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !!_vm.formError
                                    ? _c("b-col", { staticClass: "pt-1" }, [
                                        _c(
                                          "p",
                                          { staticClass: "text-danger m-0" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.formError) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-card-actions",
                            { attrs: { "no-actions": "" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-h": "end" } },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "contact-active",
                                          type: "switch",
                                          label: _vm.$t("Ativo?"),
                                        },
                                        model: {
                                          value: _vm.contactForm.active,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "active",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.active",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2956024532
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }