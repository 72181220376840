var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isReadOnly
        ? _c(
            "b-row",
            { staticClass: "pb-1" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c("e-button", {
                    attrs: { variant: "primary", text: _vm.$t("Adicionar") },
                    on: { click: _vm.onShowSidebar },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-table", {
                ref: "bank-table",
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  fields: _vm.fields,
                  items: _vm.banksList,
                  "empty-text": _vm.emptyTableMessage,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(branch)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.item.branch) +
                            "-" +
                            _vm._s(row.item.branchDigit) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(account)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.item.account) +
                            "-" +
                            _vm._s(row.item.accountDigit) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(active)",
                    fn: function (row) {
                      return [
                        _c("e-status-badge", {
                          attrs: { status: row.item.active },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-grid-actions", {
                          attrs: { "show-update": true, "show-delete": true },
                          on: {
                            update: function ($event) {
                              return _vm.onShowSidebar(row)
                            },
                            delete: function ($event) {
                              return _vm.deleteBank(row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isReadOnly
        ? _c("e-sidebar", {
            attrs: {
              show: _vm.showSidebar,
              fetching: _vm.loading,
              title: _vm.isEdit
                ? _vm.$t("Alterar Banco")
                : _vm.$t("Novo Banco"),
              width: "600px",
            },
            on: {
              save: _vm.addBankClick,
              hidden: function ($event) {
                _vm.showSidebar = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "FormulateForm",
                        {
                          ref: "formBank",
                          attrs: { name: "formBank" },
                          on: { submit: _vm.addBankClick },
                        },
                        [
                          _c(
                            "b-card-actions",
                            {
                              attrs: {
                                title: _vm.$t("Banco"),
                                "no-actions": "",
                              },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "bank-name",
                                          type: "vue-select",
                                          label: _vm.$t("Banco"),
                                          validation: "required",
                                          options: _vm.banks(),
                                        },
                                        model: {
                                          value: _vm.bankForm.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bankForm, "name", $$v)
                                          },
                                          expression: "bankForm.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "bank-branch",
                                          type: "number",
                                          label: _vm.$t("Agencia"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.bankForm.branch,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankForm,
                                              "branch",
                                              $$v
                                            )
                                          },
                                          expression: "bankForm.branch",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "bank-branch-digit",
                                          type: "number",
                                          label: _vm.$t("Dígito"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.bankForm.branchDigit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankForm,
                                              "branchDigit",
                                              $$v
                                            )
                                          },
                                          expression: "bankForm.branchDigit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "bank-account",
                                          type: "number",
                                          label: _vm.$t("Conta"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.bankForm.account,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankForm,
                                              "account",
                                              $$v
                                            )
                                          },
                                          expression: "bankForm.account",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required",
                                        attrs: {
                                          id: "bank-account-digit",
                                          type: "number",
                                          label: _vm.$t("Dígito"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.bankForm.accountDigit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankForm,
                                              "accountDigit",
                                              $$v
                                            )
                                          },
                                          expression: "bankForm.accountDigit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-card-actions",
                            { attrs: { "no-actions": "" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-h": "end" } },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "bank-active",
                                          type: "switch",
                                          label: _vm.$t("Ativo?"),
                                        },
                                        model: {
                                          value: _vm.bankForm.active,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankForm,
                                              "active",
                                              $$v
                                            )
                                          },
                                          expression: "bankForm.active",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3252939521
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }