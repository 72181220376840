var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isReadOnly
        ? _c(
            "b-row",
            { staticClass: "pb-1" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c("e-button", {
                    attrs: { variant: "primary", text: _vm.$t("Adicionar") },
                    on: {
                      click: function () {
                        return _vm.onShowSidebar()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-table", {
                ref: "address-table",
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  fields: _vm.fields,
                  items: _vm.addresses,
                  "empty-text": _vm.emptyTableMessage,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(address)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.item.publicPlace) +
                            ", " +
                            _vm._s(row.item.number) +
                            " - " +
                            _vm._s(row.item.complement) +
                            " (" +
                            _vm._s(row.item.city) +
                            "/" +
                            _vm._s(row.item.province) +
                            ") "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(active)",
                    fn: function (row) {
                      return [
                        _c("e-status-badge", {
                          attrs: { status: row.item.active },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-grid-actions", {
                          attrs: {
                            "is-active": row.item.active,
                            "show-update": true,
                            "show-delete": false,
                            "show-activate": true,
                            "show-deactivate": true,
                          },
                          on: {
                            update: function () {
                              return _vm.onShowSidebar(row)
                            },
                            activate: function ($event) {
                              return _vm.onActiveInactiveAddress(row)
                            },
                            deactivate: function ($event) {
                              return _vm.onActiveInactiveAddress(row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isReadOnly
        ? _c("e-sidebar", {
            attrs: {
              show: _vm.showSidebar,
              fetching: _vm.loading,
              title: _vm.isEdit
                ? _vm.$t("Alterar Endereço")
                : _vm.$t("Novo Endereço"),
              width: "600px",
            },
            on: {
              save: _vm.addAddressClick,
              hidden: function ($event) {
                _vm.showSidebar = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("e-address", {
                        key: _vm.formKey,
                        ref: "addressForm",
                        attrs: { "show-map": false },
                        model: {
                          value: _vm.address,
                          callback: function ($$v) {
                            _vm.address = $$v
                          },
                          expression: "address",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3817701153
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }