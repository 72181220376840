<template>
  <div>
    <b-row
      v-if="!isReadOnly"
      class="pb-1"
    >
      <b-col class="d-flex justify-content-end">
        <e-button
          variant="primary"
          :text="$t('Adicionar')"
          @click="onShowSidebar"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="bank-table"
          show-empty
          responsive
          class="bordered"
          :fields="fields"
          :items="banksList"
          :empty-text="emptyTableMessage"
        >
          <template #cell(branch)="row">
            {{ row.item.branch }}-{{ row.item.branchDigit }}
          </template>

          <template #cell(account)="row">
            {{ row.item.account }}-{{ row.item.accountDigit }}
          </template>

          <template #cell(active)="row">
            <e-status-badge :status="row.item.active" />
          </template>

          <template #cell(action)="row">
            <e-grid-actions
              :show-update="true"
              :show-delete="true"
              @update="onShowSidebar(row)"
              @delete="deleteBank(row)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <e-sidebar
      v-if="!isReadOnly"
      :show="showSidebar"
      :fetching="loading"
      :title="isEdit ? $t('Alterar Banco') : $t('Novo Banco')"
      width="600px"
      @save="addBankClick"
      @hidden="showSidebar = false"
    >
      <template #content>
        <FormulateForm
          ref="formBank"
          name="formBank"
          @submit="addBankClick"
        >
          <b-card-actions
            :title="$t('Banco')"
            no-actions
          >
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="bank-name"
                  v-model="bankForm.name"
                  type="vue-select"
                  :label="$t('Banco')"
                  validation="required"
                  class="required"
                  :options="banks()"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="bank-branch"
                  v-model="bankForm.branch"
                  type="number"
                  :label="$t('Agencia')"
                  validation="required"
                  class="required"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="bank-branch-digit"
                  v-model="bankForm.branchDigit"
                  type="number"
                  :label="$t('Dígito')"
                  validation="required"
                  class="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="bank-account"
                  v-model="bankForm.account"
                  type="number"
                  :label="$t('Conta')"
                  validation="required"
                  class="required"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="bank-account-digit"
                  v-model="bankForm.accountDigit"
                  type="number"
                  :label="$t('Dígito')"
                  validation="required"
                  class="required"
                />
              </b-col>
            </b-row>
          </b-card-actions>

          <b-card-actions no-actions>
            <b-row align-h="end">
              <b-col md="2">
                <FormulateInput
                  id="bank-active"
                  v-model="bankForm.active"
                  type="switch"
                  :label="$t('Ativo?')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </FormulateForm>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import { banks } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESidebar from '../ESidebar.vue'
import EButton from '../EButton.vue'
import EGridActions from '../EGridActions.vue'
import EStatusBadge from '../EStatusBadge.vue'

const getInitialForm = () => ({
  id: null,
  localId: null,
  name: '',
  branch: '',
  branchDigit: '',
  account: '',
  accountDigit: '',
  active: true,
})

export default {
  name: 'BankTab',
  components: {
    BTable,
    BCol,
    BRow,
    ESidebar,
    EButton,
    BCardActions,
    EGridActions,
    EStatusBadge,
  },

  mixins: [banks],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    banksList: {
      type: Array,
      required: true,
    },

    /**
     * Para personalização no componente, valores aceitos: None | Customer | Supplier
     */
    person: {
      type: String,
      required: false,
      default: 'None',
      validation(value) {
        return ['None', 'Customer', 'Supplier'].includes(value)
      },
    },
  },

  data() {
    return {
      loading: false,
      bankForm: getInitialForm(),
      showSidebar: false,
      isEdit: false,
    }
  },

  computed: {
    emptyTableMessage() {
      if (this.person === 'Customer')
        return this.$t('NENHUM DADO BANCÁRIO INFORMADO PARA ESTE CLIENTE ATÉ O MOMENTO')
      if (this.person === 'Supplier')
        return this.$t('NENHUM DADO BANCÁRIO INFORMADO PARA ESTE FORNECEDOR ATÉ O MOMENTO')

      return this.$t('NENHUM DADO BANCÁRIO INFORMADO ATÉ O MOMENTO')
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isReadOnly,
        },
        {
          label: this.$t('Banco'),
          key: 'name',
          class: 'text-left',
        },
        {
          label: this.$t('Agência'),
          key: 'branch',
          class: 'text-center',
        },
        {
          label: this.$t('Conta'),
          key: 'account',
          class: 'text-center',
        },
        {
          label: this.$t('Ativo'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
      ].filter(f => !f.hide)
    },
  },

  methods: {
    async onShowSidebar(row = null) {
      this.showSidebar = true
      this.cleanForm()

      if (row) {
        try {
          this.isEdit = true
          this.loading = true
          await new Promise(resolve => setTimeout(() => resolve(), 500))
          this.bankForm = { ...row.item }
        } finally {
          this.loading = false
        }
      }
    },

    addBankClick() {
      this.$refs.formBank.showErrors()
      if (this.$refs.formBank.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      if (this.isEdit) {
        this.$emit('update-bank', { ...this.bankForm })
      } else {
        this.$emit('add-bank', { ...this.bankForm })
      }

      this.showSidebar = false
      this.cleanForm()
    },

    async deleteBank(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const id = row.item.id || row.item.localId
          this.$emit('remove-bank', id)
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    cleanForm() {
      this.bankForm = getInitialForm()
      this.isEdit = false
    },
  },
}
</script>

<style></style>
