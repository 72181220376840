<template>
  <div>
    <b-row
      v-if="!isReadOnly"
      class="pb-1"
    >
      <b-col class="d-flex justify-content-end">
        <e-button
          variant="primary"
          :text="$t('Adicionar')"
          @click="() => onShowSidebar()"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="address-table"
          show-empty
          responsive
          class="bordered"
          :fields="fields"
          :items="addresses"
          :empty-text="emptyTableMessage"
        >
          <template #cell(address)="row">
            {{ row.item.publicPlace }}, {{ row.item.number }} -
            {{ row.item.complement }}
            ({{ row.item.city }}/{{ row.item.province }})
          </template>

          <template #cell(active)="row">
            <e-status-badge :status="row.item.active" />
          </template>

          <template #cell(action)="row">
            <e-grid-actions
              :is-active="row.item.active"
              :show-update="true"
              :show-delete="false"
              :show-activate="true"
              :show-deactivate="true"
              @update="() => onShowSidebar(row)"
              @activate="onActiveInactiveAddress(row)"
              @deactivate="onActiveInactiveAddress(row)"
            />
            <!-- @delete="deleteAddress(row)" -->
          </template>
        </b-table>
      </b-col>
    </b-row>

    <e-sidebar
      v-if="!isReadOnly"
      :show="showSidebar"
      :fetching="loading"
      :title="isEdit ? $t('Alterar Endereço') : $t('Novo Endereço')"
      width="600px"
      @save="addAddressClick"
      @hidden="showSidebar = false"
    >
      <template #content>
        <e-address
          ref="addressForm"
          :key="formKey"
          v-model="address"
          :show-map="false"
        />
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { address } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import ESidebar from '../ESidebar.vue'
import EAddress from '../EAddress.vue'
import EGridActions from '../EGridActions.vue'

export default {
  name: 'EAddressTab',

  components: {
    BTable,
    BCol,
    BRow,
    EButton,
    EStatusBadge,
    ESidebar,
    EAddress,
    EGridActions,
  },

  mixins: [address],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    addresses: {
      type: Array,
      required: true,
    },

    /**
     * Para personalização no componente
     * valores aceitos: None | Customer | Supplier
     */
    person: {
      type: String,
      required: false,
      default: 'None',
      validation(value) {
        return ['None', 'Customer', 'Supplier'].includes(value)
      },
    },
  },

  data() {
    return {
      loading: false,
      formKey: 0,
      showSidebar: false,
      isEdit: false,
      statusAddress: {
        active: true,
        default: false,
      },
    }
  },

  computed: {
    emptyTableMessage() {
      if (this.person === 'Customer')
        return this.$t('NENHUM ENDEREÇO INFORMADO PARA ESTE CLIENTE ATÉ O MOMENTO')
      if (this.person === 'Supplier')
        return this.$t('NENHUM ENDEREÇO INFORMADO PARA ESTE FORNECEDOR ATÉ O MOMENTO')

      return this.$t('NENHUM ENDEREÇO INFORMADO ATÉ O MOMENTO')
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isReadOnly,
        },
        {
          label: this.$t('CEP'),
          key: 'zipcode',
          class: 'text-center',
        },
        {
          label: this.$t('Endereço'),
          key: 'address',
          class: 'text-left',
        },
        {
          label: this.$t('Bairro'),
          key: 'neighborhood',
          class: 'text-center',
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
      ].filter(f => !f.hide)
    },
  },

  methods: {
    async onShowSidebar(row = null) {
      this.showSidebar = true
      this.cleanForm()
      if (row) {
        try {
          this.loading = true
          this.isEdit = true
          await new Promise(resolve => setTimeout(() => resolve(), 500))
          this.address = { ...row.item }
          this.statusAddress = { active: row.item.active, default: row.item.default }
        } finally {
          this.loading = false
        }
      }
    },

    addAddressClick() {
      if (!this.$refs.addressForm.validate()) {
        this.showInvalidDataMessage()
        return
      }

      if (this.isEdit) {
        this.$emit('update-address', { ...this.address, ...this.statusAddress })
      } else {
        this.$emit('add-address', { ...this.address, ...this.statusAddress })
      }

      this.showSidebar = false
      this.cleanForm()
    },

    async deleteAddress(row) {
      try {
        const confirm = await this.confirm()
        const index = row.item.id || row.item.localId
        if (confirm) {
          this.$emit('remove-address', index)
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    cleanForm() {
      this.isEdit = false

      this.address = { ...address.data().address, id: null, localId: null }
      this.statusAddress = {
        active: true,
        default: false,
      }
      this.formKey += 1
    },

    async onActiveInactiveAddress(row) {
      const confirmed = await this.confirm()
      if (!confirmed) return
      // eslint-disable-next-line no-param-reassign
      row.item.active = !row.item.active
    },
  },
}
</script>

<style></style>
