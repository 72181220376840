<template>
  <div>
    <b-row
      v-if="!isReadOnly"
      class="pb-1"
    >
      <b-col class="d-flex justify-content-end">
        <e-button
          variant="primary"
          :text="$t('Adicionar')"
          @click="onShowSidebar"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="contact-table"
          show-empty
          responsive
          class="bordered"
          :fields="fields"
          :items="contacts"
          :empty-text="emptyTableMessage"
        >
          <template #cell(telephone)="row">
            {{ row.item.telephone.number }}
          </template>

          <template #cell(active)="row">
            <e-status-badge :status="row.item.active" />
          </template>

          <template #cell(action)="row">
            <e-grid-actions
              :show-update="true"
              :show-delete="true"
              @update="onShowSidebar(row)"
              @delete="deleteContact(row)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <e-sidebar
      v-if="!isReadOnly"
      :show="showSidebar"
      :fetching="loading"
      :title="isEdit ? $t('Alterar Contato') : $t('Novo Contato')"
      width="600px"
      @save="addContactClick"
      @hidden="showSidebar = false"
    >
      <template #content>
        <FormulateForm
          ref="formContact"
          name="formContact"
        >
          <b-card-actions
            :title="$t('Contato')"
            no-actions
          >
            <b-row>
              <b-col md="6">
                <FormulateInput
                  id="contact-name"
                  v-model="contactForm.name"
                  type="text"
                  :label="$t('Nome')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="contact-role-description"
                  v-model="contactForm.roleDescription"
                  type="text"
                  :label="$t('Cargo')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="contact-phone-type"
                  v-model="contactForm.telephone.type"
                  type="vue-select"
                  :placeholder="$t('Nenhum')"
                  :label="$t('Tipo do Telefone')"
                  :options="telephoneTypes()"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="contact-phone"
                  v-model="contactForm.telephone.number"
                  type="text-mask"
                  :mask="['(##) #####-####', '(##) ####-####']"
                  validation="optional|min:14"
                  :placeholder="$t('(99) 99999-9999')"
                  :label="$t('Número')"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="contact-email"
                  v-model="contactForm.email"
                  type="text"
                  :label="$t('E-mail')"
                  :placeholder="$t('nome@email.com')"
                  validation="optional|email"
                />
              </b-col>

              <b-col
                v-if="!!formError"
                class="pt-1"
              >
                <p class="text-danger m-0">
                  {{ formError }}
                </p>
              </b-col>
            </b-row>
          </b-card-actions>

          <b-card-actions no-actions>
            <b-row align-h="end">
              <b-col md="2">
                <FormulateInput
                  id="contact-active"
                  v-model="contactForm.active"
                  type="switch"
                  :label="$t('Ativo?')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </FormulateForm>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { telephoneTypes } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESidebar from '../ESidebar.vue'
import EGridActions from '../EGridActions.vue'

const getInitialForm = () => ({
  id: null,
  localId: null,
  name: '',
  roleDescription: '',
  telephone: {
    type: null,
    number: '',
  },
  email: '',
  active: true,
})

export default {
  name: 'EContactTab',
  components: {
    BTable,
    BCol,
    BRow,
    EButton,
    EStatusBadge,
    ESidebar,
    EGridActions,
    BCardActions,
  },

  mixins: [telephoneTypes],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      required: true,
    },

    /**
     * Para personalização no componente
     * valores aceitos: None | Customer | Supplier
     */
    person: {
      type: String,
      required: false,
      default: 'None',
      validation(value) {
        return ['None', 'Customer', 'Supplier'].includes(value)
      },
    },
  },

  data() {
    return {
      loading: false,
      formError: '',
      contactForm: getInitialForm(),
      showSidebar: false,
      isEdit: false,
    }
  },

  computed: {
    emptyTableMessage() {
      if (this.person === 'Customer')
        return this.$t('NENHUM CONTATO INFORMADO PARA ESTE CLIENTE ATÉ O MOMENTO')
      if (this.person === 'Supplier')
        return this.$t('NENHUM CONTATO INFORMADO PARA ESTE FORNECEDOR ATÉ O MOMENTO')

      return this.$t('NENHUM CONTATO INFORMADO ATÉ O MOMENTO')
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isReadOnly,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
        },
        {
          label: this.$t('Cargo'),
          key: 'roleDescription',
          class: 'text-left',
        },
        {
          label: this.$t('Telefone'),
          key: 'telephone',
          class: 'text-center',
        },
        {
          label: this.$t('E-mail'),
          key: 'email',
          class: 'text-left',
        },
        {
          label: this.$t('Ativo'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
      ].filter(f => !f.hide)
    },
  },

  methods: {
    async onShowSidebar(row = null) {
      this.showSidebar = true
      this.cleanForm()

      if (row) {
        try {
          this.loading = true
          this.isEdit = true

          await new Promise(resolve => setTimeout(() => resolve(), 500))

          this.contactForm = { ...row.item }
        } finally {
          this.loading = false
        }
      }
    },

    addContactClick() {
      this.formError = ''
      const data = this.contactForm

      this.$refs.formContact.showErrors()
      const hasEmailOrContact = data.telephone.type || data.telephone.number || data.email
      const hasIncompletecontact = !(data.telephone.type && data.telephone.number)

      if (!hasEmailOrContact) {
        this.formError = this.$t('Deve preencher o telefone ou e-mail')
      } else if (!data.email && hasIncompletecontact) {
        this.formError = this.$t('Deve preencher o tipo e o número do telefone')
      }

      if (this.$refs.formContact.hasErrors || !!this.formError) {
        this.showInvalidDataMessage()
        return
      }

      if (this.isEdit) {
        this.$emit('update-contact', { ...this.contactForm })
      } else {
        this.$emit('add-contact', { ...this.contactForm })
      }

      this.showSidebar = false
      this.cleanForm()
    },

    async deleteContact(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const id = row.item.id || row.item.localId
          this.$emit('remove-contact', id)
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    cleanForm() {
      this.isEdit = false
      this.contactForm = getInitialForm()
      this.formError = ''
    },
  },
}
</script>

<style></style>
