import EAddressTab from './EAddressTab.vue'
import EBankPixTab from './EBankPixTab.vue'
import EBankTab from './EBankTab.vue'
import EContactTab from './EContactTab.vue'

export {
  EAddressTab,
  EBankPixTab,
  EBankTab,
  EContactTab,
}
